<template>
  <div class="pa-5">
    <v-icon class="primary--text" size="40px">mdi-account-outline</v-icon>
    <h3 class="headline mx-auto font-weight-bold">Propietario</h3>
    <p class="text-body-1">
      Completa la información del propietario del negocio.
    </p>
    <v-card class="grey lighten-4 rounded-xl mx-1">
      <v-card-title class="">
        <p class="font-weight-bold text-body-1">Información General</p>
      </v-card-title>
      <v-card-text>
        <pat-input
          label="Nombre completo"
          placeholder="Delia Morales Jimenez"
          v-model="owner.name"
        />
        <br />
        <pat-input
          label="Télefono de contacto"
          placeholder="55 5555 5555"
          v-model="owner.phone"
          :maxlength="10"
          :isNumber="true"
          :rules="[rules.required]"
        />
        <br />
        <pat-input
          label="Correo electrónico"
          placeholder="ejemplo@correo.com"
          v-model="owner.email"
        />
      </v-card-text>
    </v-card>
    <br />
    <v-card class="grey lighten-4 rounded-xl mx-1">
      <v-card-title class="">
        <p class="font-weight-bold text-body-1">
          Facturación <span class="grey--text">(Opcional)</span>
        </p>
        <p class="text-body-1">
          Podrás regresar a editar estos campos en cualquier momento.
        </p>
      </v-card-title>
      <v-card-text>
        <pat-select
          label="Tipo de persona"
          placeholder="Selecciona una opción"
          v-model="owner.personType"
          :items="personTypes"
        />
        <br />
        <pat-input
          label="RFC"
          placeholder="LOMP8206281HO"
          v-model="owner.rfc"
        />
        <br />
        <pat-input
          label="Direccion fiscal"
          placeholder="Tabasco 134"
          v-model="owner.address"
        />
        <span class="caption"
          >Como aparece en tu Constancia de Situación Fiscal</span
        >
      </v-card-text>
    </v-card>
    <v-checkbox v-model="acceptTerms">
      <template v-slot:label>
        <span class="caption"
          >Para crear tu cuenta es necesario que aceptes los
          <a
            @click.prevent="
              showTerms = true;
              acceptTerms = false;
            "
            >Términos y Condiciones de PAT</a
          >
          y <a>el Aviso de Privacidad</a></span
        >
      </template>
    </v-checkbox>
    <br />
    <v-btn
      class="rounded-xl"
      color="secondary"
      block
      tile
      @click="saveProduct"
      large
    >
      Crear cuenta</v-btn
    >

    <v-dialog v-model="showTerms">
      <v-card class="rounded-xl">
        <v-card-title
          ><h3 class="text-body-1 font-weight-bold">
            Términos y Condiciones
          </h3></v-card-title
        >
        <v-card-text style="overflow-y: scroll; max-height: 500px">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            quod, voluptates, quae, quia quibusdam dolorum quos nemo
            exercitationem quidem voluptatum doloremque. Quisquam quod,
            voluptates, quae, quia quibusdam dolorum quos nemo exercitationem
            quidem voluptatum doloremque.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            quod, voluptates, quae, quia quibusdam dolorum quos nemo
            exercitationem quidem voluptatum doloremque. Quisquam quod,
            voluptates, quae, quia quibusdam dolorum quos nemo exercitationem
            quidem voluptatum doloremque.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            quod, voluptates, quae, quia quibusdam dolorum quos nemo
            exercitationem quidem voluptatum doloremque. Quisquam quod,
            voluptates, quae, quia quibusdam dolorum quos nemo exercitationem
            quidem voluptatum doloremque.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            quod, voluptates, quae, quia quibusdam dolorum quos nemo
            exercitationem quidem voluptatum doloremque. Quisquam quod,
            voluptates, quae, quia quibusdam dolorum quos nemo exercitationem
            quidem voluptatum doloremque.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <div>
            <v-btn
              class="rounded-pill mx-auto"
              color="secondary"
              min-width="200px"
              rounded
              large
              @click="
                showTerms = false;
                acceptTerms = true;
              "
              >&nbsp;&nbsp;&nbsp;Aceptar&nbsp;&nbsp;&nbsp;</v-btn
            >
          </div>
          <br />
          <div>
            <a @click="showTerms = false">Regresar</a>
          </div>
          <br />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Negocio",
  data() {
    return {
      acceptTerms: false,
      showTerms: false,
      owner: {
        name: "",
        phone: "",
        email: "",
        personType: "",
        rfc: "",
        address: "",
      },
      personTypes: ["Física", "Moral"],
    };
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas
  },
  mounted() {
    // Código que se ejecuta al montar el componente
  },
};
</script>

<style scoped>
.negocio {
  /* Estilos del componente */
}
</style>
